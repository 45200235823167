<template>
  <div>
    <hr />
    <MyAcademyTitle title="companyInfo"></MyAcademyTitle>
    <div class="mt-4 align-items-center" v-if="getSettings">
      <form @submit.prevent="checkForm">
        <div class="vid-title mb-4">
          <h2 class="title-hd">{{ $t("websiteTitle") }}</h2>
          <div class="form_field">
            <input
              type="text"
              maxlength="100"
              required
              v-model="form.name"
              :placeholder="$t('addHereMax100Char')"
            />
          </div>
        </div>
        <div class="vid-title mb-4">
          <h2 class="title-hd">{{ $t("email") }}</h2>
          <div class="form_field">
            <input
              type="email"
              maxlength="100"
              disabled
              required
              :value="form.email"
              :placeholder="$t('addHereMax100Char')"
            />
          </div>
          <div class="bg-light card p-3 my-3 card">
            <p>{{ $t("emailCantChange") }}</p>
          </div>
        </div>
        <div class="vid-title mb-4">
          <h2 class="title-hd">{{ $t("phone") }}</h2>
          <div class="form_field">
            <input
              type="tel"
              pattern="\+[0-9]{0,20}$"
              v-model="form.phone"
              :title="$t('patternPhone')"
              maxlength="20"
              :placeholder="$t('addHereN', { n: 20 })"
            />
          </div>
        </div>
        <div class="vid-title mb-4">
          <h2 class="title-hd">{{ $t("companyAddress") }}</h2>
          <div class="form_field">
            <textarea
              maxlength="250"
              required
              rows="4"
              v-model="form.address"
              :placeholder="$t('addHereN', { n: 250 })"
            />
          </div>
          <div class="bg-light card p-3 my-3 card">
            <p>{{ $t("companyAddressInfo") }}</p>
          </div>
        </div>
        <button
          @submit="checkForm"
          type="submit"
          :disabled="actionIsLoading"
          class="btn btn-pill subscribe"
        >
          <Loading v-if="actionIsLoading"></Loading>
          <span v-else>{{ $t("update") }}</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import MyAcademyTitle from "./MyAcademyTitle.vue";
import Loading from "../Shared/Loading.vue";
import actionMixin from "./action.mixin";
export default {
  components: { MyAcademyTitle, Loading },
  mixins: [actionMixin],
  data() {
    return {
      form: {
        name: "",
        phone: "",
        address: "",
        email: "",
      },
    };
  },
  methods: {
    checkForm(e) {
      e.preventDefault();
      if (!this.form.phone) {
        this.form.phone = "+";
      }
      this.companyPut(this.form);
    },
  },
  mounted() {
    this.form = {
      ...this.getSettings,
      name: this.getSettings.title,
      phone: this.getSettings.phone === "+" ? "" : this.getSettings.phone,
    };
  },
};
</script>

<style></style>
